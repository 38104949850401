.promocion-modal-dropzone__container {
    padding: 10px 20px;
}

.promocion-modal-dropzone {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-content: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    min-height: 100px!important;
    border: 1px dashed #949494;
    background: #f9f8f8;
    cursor: pointer;
}

.promotion-thumb__container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-content: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.promotion-thumb__thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
}

.promotion-thumb__inner {
    display: flex;
    min-width: 0;
    overflow: hidden;
}

.promotion-thumb__image {
    display: block;
    width: auto;
    height: 100%;
}